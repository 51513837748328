<template>
  <div
    class="
      bg-white
      border-b border-gray-100
      flex flex-col
      pt-24
      justify-center
      py-24
      px-6
      lg:px-8
      relative
    "
    id="register"
  >
    <svg
      class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20 z-10"
      width="404"
      height="384"
      fill="none"
      viewBox="0 0 404 384"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="de316486-4a29-4312-bdfc-fbce2132a2c1"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect
            x="0"
            y="0"
            width="4"
            height="4"
            class="text-gray-200"
            fill="currentColor"
          />
        </pattern>
      </defs>
      <rect
        width="404"
        height="384"
        fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
      />
    </svg>
    <svg
      class="hidden lg:block absolute bottom-0 left-0 -ml-20 z-10"
      width="404"
      height="384"
      fill="none"
      viewBox="0 0 404 384"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="de316486-4a29-4312-bdfc-fbce2132a2c1"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect
            x="0"
            y="0"
            width="4"
            height="4"
            class="text-gray-200"
            fill="currentColor"
          />
        </pattern>
      </defs>
      <rect
        width="404"
        height="384"
        fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
      />
    </svg>
    <div class="sm:mx-auto relative z-20">
      <h2
        class="
          mt-1
          text-4xl
          font-extrabold
          text-gray-900
          sm:text-5xl sm:tracking-tight
          lg:text-6xl
          text-center
        "
      >
        Request Password Reset
      </h2>
      <div class="relative text-lg text-gray-700 font-medium mt-4 mb-4">
        <p class="relative text-center mb-5">
          Request email to reset your password. Please enter your details below.
        </p>
      </div>
    </div>
    <!-- IF PASSWORD RESET TOKEN IS VALID -->
    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-xl relative z-20">
      <div
        class="
          border-t border-gray-50
          py-8
          px-4
          shadow-xl
          sm:rounded-lg sm:px-10
          bg-white
        "
      >
          <div class="space-y-6">
            <LoadingSpinner
              v-if="isLoading"
              class="justify-center items-center"
            />
            <section
              v-if="!isLoading"
              class=""
              aria-labelledby="gallery-heading"
            >
              <div class="flex flex-col">
                <div class="">
                  <div>
                    <!-- show success -->
                    <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-4" >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/x-circle -->
                          <svg
                            class="h-5 w-5 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-green-800">Success</h3>
                          <div class="mt-2 text-green-700">
                            <p class=" text-green-700 text-sm ">{{emailMessage}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- show failed message -->
                    <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/x-circle -->
                          <svg
                            class="h-5 w-5 text-red-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">Error</h3>
                          <div class="mt-2 text-red-700">
                            <p class=" text-red-700 text-sm ">{{emailMessage}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email Address
                    </label>
                    <div class="mt-1 relative">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-400"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg></div>
                      <input
                        @input="updateEmail($event.target.value)"
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="current-email"
                        required=""
                        class="
                          appearance-none
                          block
                          pl-10
                          w-full
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                        v-bind:class="[
                          emailMessage
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                            : '',
                        ]"
                      />
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"   v-if="emailMessage">
                      <!-- Heroicon name: solid/exclamation-circle -->
                      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="mt-4 flex justify-end">
            <button
              type="submit"
              class="
                border border-transparent
                rounded-md
                shadow-sm
                py-2
                px-4
                inline-flex
                justify-center
                text-sm
                font-medium
                text-white
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-blue-400
                          w-full
              "
              :disabled="!email"
              v-on:click="submit()"
              v-bind:class="[
                email
                  ? 'bg-indigo-600 hover:bg-blue-700'
                  : 'bg-indigo-400 cursor-not-allowed',
              ]"
            >
              Reset Password
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { NopService } from "../services/nopService";
import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
  components: {
    LoadingSpinner,
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const gtoken = await executeRecaptcha('login')
      // Do stuff with the received token.
      let resp = await NopService.validateRecaptcha(gtoken)
      return (resp.data.status == 1)
    }
    return {
      recaptcha,
    };
  },
  data() {
    return {
      isLoading: false,
      email: "",
      emailMessage: "",
      showSuccess: false,
      showFailure: false,
      isValidRecaptcha: false,
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      this.isValidRecaptcha = await this.recaptcha();
      if (this.isValidRecaptcha && this.email) {
        let resp = await NopService.requestPasswordReset(this.email);
        this.emailMessage = resp.Result;
        if (resp.Result == "Email with instructions has been sent to you.") {
          this.email = "";
          this.showSuccess = true;
          this.showFailure = false;
        }
        else {
          this.showSuccess = false;
          this.showFailure = true;
        }
      }
      this.isLoading = false;
    },
    updateEmail(value) {
      this.email = value;
    },
  },
};
</script>
